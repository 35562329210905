import React, { useState } from "react";
import ReactPlayer from "react-player";
import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: "Comic Sans MS", cursive, sans-serif;

    /* Rainbow scrollbar */
    scrollbar-width: thin;
    scrollbar-color: #ff006e #fff;

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(90deg, #ff006e, #ffbe0b, #3a86ff, #8338ec, #fb5607);
      border-radius: 6px;
    }

    /* Custom Rainbow Cursor */
    cursor: url('https://www.cursors-4u.com/cursor/2014/03/25/sexy-red-lips.html'), auto;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(180deg, #8338ec, #ff006e);
`;

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .react-player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    filter: blur(15px) brightness(40%);
    pointer-events: none;
  }
`;

const ControlsContainer = styled.div`
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  text-align: center;

  button {
    background: linear-gradient(90deg, #ff006e, #ffbe0b, #3a86ff, #8338ec, #fb5607);
    border: none;
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    cursor: pointer;
    margin: 5px;
    border-radius: 5px;
    outline: none;

    &:hover {
      opacity: 0.9;
      box-shadow: 0px 0px 10px #fff;
    }
  }

  input[type="range"] {
    width: 80%;
    margin-top: 10px;
    -webkit-appearance: none;
    appearance: none;
    background: linear-gradient(90deg, #ff006e, #ffbe0b, #3a86ff, #8338ec, #fb5607);
    height: 8px;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
  }
`;

const TextBehind = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: white;
  font-size: 3rem; /* Reduced text size */
  font-weight: bold;
  text-align: center;
  text-shadow: 3px 3px 15px rgba(0, 0, 0, 0.8);
  background: linear-gradient(90deg, #ff006e, #ffbe0b, #3a86ff, #8338ec, #fb5607);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const GifContainer = styled.img`
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 4;
  width: 350px;  /* Increased GIF size */
  height: auto;
  border: 5px solid white;
  border-radius: 10px;
  box-shadow: 0px 0px 20px #ff006e;
`;

const VideoTitle = styled.div`
  position: absolute;
  bottom: 130px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
  background: linear-gradient(90deg, #ff006e, #ffbe0b, #3a86ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const App = () => {
  const [playing, setPlaying] = useState(true);
  const [volume, setVolume] = useState(0.5);
  const [played, setPlayed] = useState(0);

  const togglePlayPause = () => {
    setPlaying(!playing);
  };

  const handleVolumeChange = (e) => {
    setVolume(e.target.value / 100);
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value / 100));
  };

  const handleProgress = (state) => {
    setPlayed(state.played);
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <VideoContainer>
          <ReactPlayer
            className="react-player"
            url="hhttps://www.youtube.com/watch?v=4S_vclBlGZo"
            playing={playing}
            loop
            volume={volume}
            muted={false}
            controls={false}
            width="100%"
            height="100%"
            onProgress={handleProgress}
            autoplay= {true}
          />
        </VideoContainer>

        <GifContainer
          src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExajJkMGpvNHJlYm5zczBmMDE3Z2Iyb20yaG54dzc1cmE0NjgxdTljOSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3ohs7Llnjv2ubyxv1e/giphy.gif"
          alt="Rainbow GIF"
        />

        <TextBehind> yasssss bitch!</TextBehind>



        <ControlsContainer>
          <button onClick={togglePlayPause}>
            {playing ? "Pause 🌈" : "Play 🌈"}
          </button>

          <input
            type="range"
            min="0"
            max="100"
            value={played * 100}
            onChange={handleSeekChange}
          />

          <input
            type="range"
            min="0"
            max="100"
            value={volume * 100}
            onChange={handleVolumeChange}
          />
        </ControlsContainer>
      </Container>
    </>
  );
};

export default App;
